import React from 'react'

import HeaderBox from './HeaderBox'
import Logo from './Logo'

// @ts-ignore
import logo from '../../lib/images/logo.png'

export default function LoggedOutHeader() {
  return (
    <HeaderBox className="d-flex justify-content-start position-fixed align-items-center">
      <div className="d-flex align-items-center">
        <Logo src={logo} />

        <h1 className="text-xxl bold m-0">Radimal</h1>
      </div>
    </HeaderBox>
  )
}
