export enum OrganizationEvent {
  AddedIndication = 35,
  AddedPrivateNotes = 90,
  AttemptedUploadDicom = 134,
  ClickedSpecialistRecommendation = 232,
  CombinedStudies = 86,
  ComparedStudies = 87,
  DownloadedInvoices = 72,
  DownloadedReport = 80,
  EditedConsult = 199,
  MovedPatientImages = 166,
  NotifyStat = 100,
  OpenedBilling = 68,
  OpenedDicomServers = 70,
  OpenedInviteYourFriends = 133,
  OpenedInvoices = 71,
  OpenedSettings = 69,
  OpenedUploadImage = 75,
  OpenedViewer = 34,
  PrintedReport = 79,
  ReducedImages = 85,
  RemovedIndication = 36,
  RequestedConsult = 84,
  SearchedPatient = 76,
  SelectedStatTimings = 88,
  SharedReport = 81,
  UpdatedTimeframe = 77,
  ViewedAIReport = 1,
  ViewedReportPdf = 83,
  ZoomedImage = 82,
}
