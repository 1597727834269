import './../index.scss'

import { Helmet } from 'react-helmet'
import React from 'react'
import Background from '../common/Background'

import LoggedOutHeader from '../header/LoggedOut'

interface Props {
  children: any
}

export default function LoggedOutLayout(props: Props) {
  return (
    <div className="vw-100 min-vh-100 position-absolute">
      <Helmet>
        <meta charSet="utf-8" />

        <title>Radimal</title>
      </Helmet>

      <Background />

      <LoggedOutHeader />

      <div>{props.children}</div>
    </div>
  )
}
